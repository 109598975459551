[data-ratio] {
	display: block;
	max-width: 100%;
	position: relative;
}
[data-ratio]:before {
	content: '';
	display: block;
}
[data-ratio] > * {
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
[data-ratio='16:9']:before { padding-top: 56.25%; }
[data-ratio='4:3']:before { padding-top: 75%; }
[data-ratio='3:2']:before { padding-top: 66.6666%; }
[data-ratio='1:1']:before { padding-top: 100%; }
[data-ratio='1280:500']:before { padding-top: 39.0625%; }

[data-ratio='50:36']:before { padding-top: 72%; }
[data-ratio='50:48']:before { padding-top: 96%; }
