/*
***
BONS PLANS / S MINUTES
***
*/
.plans-minutes {
	padding: 5px;
	background-image: linear-gradient(135deg, #00b1a0 40%, #ffe500 60%);
}
/* BONS PLANS */
/* .plan {
	width: 90%;
	color: #fff;
	padding: 0 2px;
	box-sizing: border-box;
}
.plan .container {
	background-size: cover;
	background-position: center;
	padding: 50px 20px;
	box-sizing: border-box;
	position: relative;
}
.plan .container:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(30, 34, 41, 0.3);
}
.plan h3 {
	margin-top: 0;
	margin-bottom: 25px;
	position: relative;
	z-index: 1;
}
.plan .day-and-night {
	width: 100px;
	margin: 0 auto 25px;
	font-size: 0.9em;
	position: relative;
	z-index: 1;
}
.plan .day-and-night > div {
	box-sizing: border-box;
}
.plan .day-and-night > div:first-child {
	border-right: 1px solid #fff;
}
.plan .day-and-night img {
	display: inline-block;
	width: 25px;
}
.plan .day-and-night span {
	display: block;
	font-size: 1.6em;
	font-weight: 500;
	margin: 8px 0 5px;
}
.plan .price {
	text-transform: uppercase;
	letter-spacing: 0.1em;
	position: relative;
	z-index: 1;
	font-size: 0.8em;
}
.plan .price span {
	display: block;
	font-family: 'Orkney';
	font-weight: bold;
	font-size: 2.8em;
	margin-top: 15px;
} */

/*
***
COUPS DE COEUR / SELECTION
***
*/
.coups-selection {
	text-align: center;
	background-image: url(../img/bg-beach.jpg);
	background-size: cover;
	background-position: 60%;
	position: relative;
}
.coups-selection:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.75);
}
/* COUPS DE COEUR */
.coups-coeur {
	position: relative;
	z-index: 1;
	padding: 5px;
	box-sizing: border-box;
}
.coups-coeur > .container {
	min-height: 100%;
	border: 1px solid #f8c2d9;
}
.coups-coeur h2 {
	color: #f8c2d9;
}
.coup {
	width: 90%;
	color: #fff;
	padding: 0 2px;
	box-sizing: border-box;
}
.coup .container {
	background-size: cover;
	background-position: center;
	padding: 50px 20px;
	box-sizing: border-box;
	position: relative;
}
.coup .container:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(66, 51, 57, 0.3);
}
.coup h3 {
	margin-top: 0;
	margin-bottom: 25px;
	position: relative;
	z-index: 1;
}
.coup .day-and-night {
	width: 100px;
	margin: 0 auto 25px;
	font-size: 0.9em;
	position: relative;
	z-index: 1;
}
.coup .day-and-night > div {
	box-sizing: border-box;
}
.coup .day-and-night > div:first-child {
	border-right: 1px solid #fff;
}
.coup .day-and-night img {
	display: inline-block;
	width: 25px;
}
.coup .day-and-night span {
	display: block;
	font-size: 1.6em;
	margin: 8px 0 5px;
}
.coup .price {
	text-transform: uppercase;
	letter-spacing: 0.1em;
	position: relative;
	z-index: 1;
	font-size: 0.8em;
}
.coup .price span {
	display: block;
	font-family: 'Orkney';
	font-weight: bold;
	font-size: 2.8em;
	margin-top: 15px;
}
/* SELECTION EDEN */
.selection-eden {
	position: relative;
	z-index: 1;
}
.departs {
	padding: 0 25px;
}
.depart {
	border-bottom: 1px solid #78766e;
	color: #fff;
}
.depart:last-child {
	border-bottom: none;
}
.depart .titre {
	display: flex;
	align-items: center;
	justify-content: center;
}
.depart .titre h3 {
	margin: 0;
	color: #ffd506;
}
.depart .prix {
	font-size: 0.8em;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	color: #ffd506;
	padding: 15px 0;
	box-sizing: border-box;
}
.depart .prix span {
	display: block;
	font-family: 'Orkney';
	font-size: 2.8em;
	line-height: 120%;
	margin-top: 2px;
}
.selection-eden .bordered {
	border-color: #ffd506;
	color: #ffd506;
}


/*
***
INFOS
***
*/
.infos {
	font-family: 'Orkney';
	font-size: 0.9em;
	text-align: center;
}
.infos .nombre {
	text-transform: uppercase;
	letter-spacing: 0.2em;
	border-right: 1px solid #000;
	box-sizing: border-box;
	padding: 10px 0;
}
.infos .nombre span {
	display: block;
	font-size: 4em;
	letter-spacing: -0.05em;
	line-height: 1em;
}
.infos .tri {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.infos .tri span {
	display: block;
	font-size: 1.5em;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}
.infos .tri select {
	padding: 5px 10px;
	border: 1px solid #eff0f0;
}

/*
***
RESULTATS
***
*/

/* TRI */
.tri-side {
	background-color: #1e2229;
}

/* RESULTATS */
.resultats {
	padding-right: 25px;
	padding-left: 25px;
}
.resultat {
	text-align: center;
	color: #fff;
	box-sizing: border-box;
	margin-bottom: 4px;
}
.resultat .container {
	background-size: cover;
	background-position: center;
	padding: 50px 20px;
	box-sizing: border-box;
	position: relative;
}
.resultat .container:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(30, 34, 41, 0.3);
}
.resultat h3 {
	margin-top: 0;
	margin-bottom: 25px;
	position: relative;
	z-index: 1;
}
.resultat .day-and-night {
	width: 100px;
	margin: 0 auto 25px;
	font-size: 0.9em;
	position: relative;
	z-index: 1;
}
.resultat .day-and-night > div {
	box-sizing: border-box;
}
.resultat .day-and-night > div:first-child {
	border-right: 1px solid #fff;
}
.resultat .day-and-night img {
	display: inline-block;
	width: 25px;
}
.resultat .day-and-night span {
	display: block;
	font-size: 1.6em;
	font-weight: 500;
	margin: 8px 0 5px;
}
.resultat .price {
	text-transform: uppercase;
	letter-spacing: 0.1em;
	position: relative;
	z-index: 1;
	font-size: 0.8em;
}
.resultat .price span {
	display: block;
	font-family: 'Orkney';
	font-weight: bold;
	font-size: 2.8em;
	margin-top: 15px;
}


/*
***
MEDIAQUERIES
***
*/
@media (min-width: 568px) {
	.resultat:nth-child(2n + 1) {
		padding-right: 2px;
	}
	.resultat:nth-child(2n + 2) {
		padding-left: 2px;
	}
}
@media (min-width: 768px) {
	/* header .bar:nth-child(1) {
		animation: loading 1.5s ease-in-out 0s infinite;
	}
	header .bar:nth-child(2) {
		animation: loading 1.5s ease-in-out 0.75s infinite;
	} */
	body {
		font-size: 92%;
	}
	h2 {
		font-size: 2.6em;
	}
	h2.big {
		margin: 0 0 50px;
	}
	h3 {
		font-size: 2.2em;
	}
	.sous-titre {
		margin-bottom: 50px;
	}
	.izi-link {
		margin-top: 50px;
	}
	.padded {
		padding-top: 75px;
		padding-bottom: 75px;
	}
	header {
		background-position: center;
	}
	header .trigger-menu {
		top: 35px;
		right: 25px;
	}
	.recherche .slick-list {
		overflow: visible;
	}
	.recherche .slick-type .slick-track {
		width: 100% !important;
		transform: none !important;
		text-align: center;
	}
	.recherche .slick-type h3 {
		opacity: 0.6;
		padding-left: 15px;
		padding-right: 15px;
		float: none;
		display: inline-block;
	}
	.recherche .slick-type h3.slick-current {
		color: #fff;
	}
	.recherche .form > div {
		padding-right: 5px;
		padding-left: 5px;
	}
	.recherche .form > div:nth-child(2n) {
		padding-right: 5px;
		padding-left: 5px;
	}
	nav .menu {
		left: 25%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;
	}
	nav .menu ul {
		padding: 0;
	}
	nav .menu ul li {
		margin: 30px 0;
	}
	/* nav .menu ul li:last-child {
		margin-top: 75px;
	} */
	nav .menu ul a {
		font-size: 2.5em;
	}
	.plans-minutes {
		padding: 10px;
	}
	.coups-coeur {
		padding: 10px;
	}
}
@media (min-width: 1024px) {
	.recherche .slick-type h3 {
		font-size: 2.2em;
	}
}
@media (min-width: 1280px) {
	/* body {
		font-size: 94%;
	} */
	/* header {
		padding: 35px 0 0;
		text-align: left;
	} */
	.not-loaded header .entete:after {
		left: 100%;
	}
	/* header .logo {
		margin-left: 35px;
	} */
	header .trigger-menu {
		position: fixed;
		/* display:none; */
		right: 35px;
	}
	header .trigger-menu.hide {
		top: 0;
		transform: translateY(-100%);
	}
	header .trigger-menu.black > span {
		color: #000;
	}
	header .trigger-menu .burger > span {
		background: #000;
	}
	header .top-menu {
		list-style-type: none;
		display: block;
		padding: 0;
		margin: 0;
		position: absolute;
		top: 50%;
		right: 35px;
		z-index: 1;
		transform: translateY(-50%);
		font-size: 0.9em;
		transition: opacity 0.4s 1.2s;
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	.not-loaded header .top-menu {
		opacity: 0;
		pointer-events: none;
	}
	header .top-menu li {
		display: inline-block;
		margin-left: 25px;
	}
	header .top-menu a {
		color: #fff;
		text-transform: uppercase;
		letter-spacing: 0.15em;
		font-weight: 500;
		padding: 5px 0;
	}
	header .top-menu a img {
		width: 18px;
		transform: translateY(2px);
		margin-right: 5px;
	}
	.punchline {
		font-size: 1.7em;
	}
	.recherche .slick-type h3 {
		cursor: pointer;
	}
	.recherche .form {
		text-align: center;
	}
	.recherche .form > div {
		/* width: 200px; */
		text-align: left;
	}
	.recherche .form > div:last-child {
		width: auto;
	}
	.recherche .form .chosen-single {
		background: rgba(30, 34, 41, 0.25);
		padding: 13px 25px;
	}
	.recherche .form .button {
		font-size: 1.5em;
		padding: 13px;
	}
	nav .menu {
		left: 50%;
	}
	/* .plan .container:before {
		transition: background-color 0.15s;
	}
	.plan:hover .container:before {
		background-color: rgba(30, 34, 41, 0.5);
	} */
	.coups-selection {
		background-position: center;
	}
	.coup .container:before {
		transition: background-color 0.15s;
	}
	.coup:hover .container:before {
		background-color: rgba(66, 51, 57, 0.5);
	}
	.selection-eden h2 {
		margin-top: 10px;
	}
}
@media (min-width: 1440px) {
	body {
		font-size: 96%;
	}
	.sous-titre {
		margin-bottom: 75px;
	}
	.padded {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	.xxl-shown {
		display: block;
	}
	.xxl-flex-shown {
		display: flex;
	}
	header .load-bar {
		height: 5px;
	}
	header .bar:nth-child(1) {
		animation: loading 2s ease-in-out 0s infinite;
	}
	header .bar:nth-child(2) {
		animation: loading 2s ease-in-out 1s infinite;
	}
	header .logo {
		margin-left: 50px;
	}
	header .top-menu {
		right: 50px;
	}
	header .top-menu li {
		margin-left: 35px;
	}
	.recherche .slick-type h3 {
		padding-left: 25px;
		padding-right: 25px;
	}
	.recherche .form > div {
		padding-right: 10px;
		padding-left: 10px;
	}
	.recherche .form > div:nth-child(2n) {
		padding-right: 10px;
		padding-left: 10px;
	}
	/* .plan {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		padding: 0 2px 4px;
		height: 375px;
	}
	.plan .container {
		height: 100%;
		padding: 25px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.plan:nth-child(2n + 1) {
		padding-left: 0;
	}
	.plan:nth-child(2n + 2) {
		padding-right: 0;
	}
	.plan .day-and-night {
		width: 125px;
	}
	.plan.slim {
		height: 275px;
	}
	.plan.slim .day-and-night {
		width: 200px;
	}
	.plan.slim .day-and-night img {
		vertical-align: middle;
		margin-right: 5px;
	}
	.plan.slim .day-and-night div div {
		display: inline-block;
		vertical-align: middle;
	}
	.plan:last-child {
		margin-top: -100px;
	} */
	.dernieres {
		max-width: 1000px;
		margin: 0 auto;
	}
	.derniere .desc {
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.derniere .desc > div {
		padding: 15px 0;
	}
	.derniere .desc h4 {
		display: block;
		margin-top: 0;
		margin-bottom: 5px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.05em;
	}
	.derniere .desc span {
		color: #939598;
	}
	.departs {
		max-width: 1000px;
		margin: 0 auto;
	}
	.depart .desc {
		text-align: left;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.depart .desc > div {
		padding: 15px 0;
	}
	.depart .desc h4 {
		display: block;
		margin-top: 0;
		margin-bottom: 5px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.05em;
	}
	.depart .desc span {
		color: #939598;
	}
	.slick-coups {
		padding: 0 25px;
		max-width: 700px;
		margin: 0 auto;
	}
	.coup {
		display: inline-block;
		width: 50%;
		padding: 0 2px 4px 0;
	}
	.coup:nth-child(2) {
		padding-right: 0;
		padding-left: 2px;
	}
	.coup:last-child {
		width: 100%;
		padding: 0;
	}
	.coup.slim .day-and-night {
		width: 200px;
	}
	.coup.slim .day-and-night img {
		vertical-align: middle;
		margin-right: 5px;
	}
	.coup.slim .day-and-night div div {
		display: inline-block;
		vertical-align: middle;
	}
}
@media (min-width: 1920px) {
	body {
		font-size: 100%;
	}
	header .logo {
		margin-left: 100px;
	}
	header .top-menu {
		right: 100px;
	}
	header .top-menu li {
		margin-left: 75px;
	}
	.punchline {
		font-size: 2em;
	}
	.recherche .slick-type h3 {
		font-size: 2.5em;
	}
	nav .menu ul a {
		font-size: 3em;
	}
	.dernieres {
		padding: 0 75px;
	}
	.departs {
		padding: 0 75px;
	}
	.slick-coups {
		padding: 0 75px;
	}
}
