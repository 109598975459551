input:not([type]), input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
	height:initial;
}

#fil_ariane {
	position:relative;
	text-align: center;
	/* background:#eee; */
}
.ariane_element {
	background:#eee;
	padding: 20px 10px;
	position:relative;
	z-index:1;
}
#fil_ariane .medium-3 {
	width: 33.33333%;
}
.ariane_element img {
	display:none;
}
.ariane_element.ariane_courant {
	background:#ffe500;
	color:#000;
	z-index:2;
}
.ariane_element.ariane_courant::before {
	content: '';
	position:absolute;
	top:0; left:0;
	display:block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 27px 0 27px 10px;
	border-color: transparent transparent transparent #eee;
}
.ariane_element.ariane_courant:nth-child(0n+1)::before { display:none; }
.ariane_element.ariane_courant:nth-child(0n+3)::after { display:none; }

.ariane_element.ariane_courant::after {
	content: '';
	position:absolute;
	top:0; left:100%;
	display:block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 27px 0 27px 10px;
	border-color: transparent transparent transparent #ffe500;
}

.sra-action-button {
	background: #ffe500;
	color: #000
}
.sra-action-button:hover {
	background: #fff170;
	color: #000
}

.new_titre1, .new_titre2, .titre3 {
	background: #eee;
	display: inline-block;
	background: linear-gradient(135deg,#2f1f5d 0,#529aa8 100%);
    background-clip: border-box;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 1.5rem 0;
	font-size: 2.2em;
	font-weight:bold;
}

.contenu_bloc { border: 1px solid #fff; }

input[type="file"], input[type="checkbox"], input[type="radio"], select { margin:0; }

abbr { color:inherit; }

button, .button { line-height:inherit; height:33px; }

select { height:33px; }
