
.slick-plans .voyage-mini > span {
	/* transform:translate(-50%,-50%) scale( 0.8 ); */
}

.bons-plans {
	text-align: center;
	background: #fff;
}
.voyage-mini {
	/* display:inline-block;
	width:50%;
	padding-bottom:10%; */
}

/* DERNIERES MINUTES */
.dernieres-minutes {
	text-align: center;
	background: #eff0f0;
}
.dernieres-minutes .izi-link {
	background: #fff;
}
.derniere {
	background: #1e2229;
	border-bottom: 1px solid #707076;
	color: #fff;
}
.derniere:last-child { border-bottom: none; }
.derniere .titre {
	display: flex;
	align-items: center;
	justify-content: center;
}
.derniere .titre h3 { margin: 0; color: #00b29e; }
.derniere .prix {
	font-size: 0.8em;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	color: #00b29e;
	padding: 15px 0;
	box-sizing: border-box;
}
.derniere .prix span {
	display: block;
	font-family: 'Orkney';
	font-size: 2.8em;
	line-height: 120%;
	margin-top: 2px;
}

.dernieres.titres .slick-current { color:#666; }
.dernieres.titres ul { list-style: none; margin:0; padding:0; }
.dernieres.titres li { display:inline-block; }
.dernieres.titres button { display:none; }
.dernieres.titres li::after {
	display:inline;
	text-transform: uppercase;
	color: #a7a9ac;
	letter-spacing: 0.15em;
	font-size: 0.8em;
	font-weight: 500;
	display: inline-block;
	margin-bottom: 25px;
	padding: 0 25px;
}
.dernieres.titres li:nth-child(1)::after { content:'CETTE SEMAINE'; }
.dernieres.titres li:nth-child(2)::after { content:'LA SEMAINE PROCHAINE'; }
.dernieres.titres li:nth-child(3)::after { content:'DANS 2 SEMAINES'; }
.dernieres.titres li.slick-active::after { color:#00b29e; }

@media (min-width: 568px) {
	.dernieres { padding: 0 25px; }
}
@media (max-width: 767px) {
	.slick-plans .voyage-mini { padding-bottom:10%; }
}


/* BLOCK NOS SERVICES */
.nos-services {
	text-align: center;
	background-image: linear-gradient(135deg, #f4c0d9 0%, #5ebcbd 100%);
}
.nos-services a {
	background:no-repeat top center / auto 40px;
	text-transform: uppercase;
	margin: 10px 0px;
	padding: 50px 20px 20px;
	box-sizing: border-box;
	transition:all 0.4s;
}
.nos-services a:hover {
	transform:scale(0.9);
}
/* /BLOCK NOS SERVICES */


/* BLOCK RECIT DE VOYAGE */
.recits-de-voyage a { display:block; margin:0 10%; transition:all 0.3s; padding:20px; }
.recits-de-voyage a:hover { transform:scale(0.95); background:#f4f4f4; }
.recits-de-voyage h2 { display:block; text-align:center; }
.recits-de-voyage h3 { font-size:1.3rem; }
.recits-de-voyage h3 span { font-size:1.1rem; }
.recits-de-voyage [data-ratio] { width:100%; }
.recits-de-voyage p { letter-spacing:initial; font-size:1.1rem; box-sizing:border-box; margin:0; padding:20px 0 0 0; text-align:justify; }

@media screen and (min-width: 64em) {
	.recits-de-voyage .cols { display:flex; flex-direction:row; align-items:stretch; }
	.recits-de-voyage .cols .img { width:33.3333%; }
	.recits-de-voyage .cols p { width:66.6666%; padding:0 0 0 5%; align-self:center; }
	.recits-de-voyage a:nth-child(2n+1) .cols { flex-direction:row-reverse; }
	.recits-de-voyage a:nth-child(2n+1) .titre { text-align:right; }
	.recits-de-voyage a:nth-child(2n+1) .cols p { padding:0 5% 0 0; }
}
/* /BLOCK RECIT DE VOYAGE */


/* BLOCK EDEN-PAGES */
body.home .eden-pages a {
	background:no-repeat center / cover;
	text-align:center;
	line-height:normal;
	overflow:hidden;
}
body.home .eden-pages a .img {
	position:absolute; top:0; left:0; width:100%; height:100%;
	background:no-repeat center / cover;
	transition:all 0.5s;
}
body.home .eden-pages a:hover .img {
	transform:scale(1.1);
	transition:all 8s;
}
body.home .eden-pages a .txt {
	background:rgba(230,230,230,0.6);
	transition:all 0.5s;
}
body.home .eden-pages a:hover .txt {
	background:rgba(230,230,230,0.2);
	transform:scale(0.8);
	transition:all 0.8s;
}
body.home .eden-pages a .eden {
	position:absolute; top:25%; left:50%; transform:translate(-50%,-50%);
	font-size:8vw;
	font-weight:bold;
	display:block;
}
body.home .eden-pages a .sub {
	position:absolute; top:35%; left:50%; transform:translate(-50%,-50%);
	font-size:6vw;
	display: block;
	text-transform: uppercase;
}
body.home .eden-pages a .btn {
	position:absolute; top:75%; left:50%; transform:translate(-50%,-50%);
	display:inline-block;
	border:1px solid #000;
	padding:4px 12px;
	font-size:4vw;
	border-radius:8px;
	white-space:nowrap;
	transition:all 0.5s;
}
body.home .eden-pages a:hover .btn {
	color:#fff;
	background:rgba(0,0,0,0.8);
}

@media (min-width: 768px) {
	body.home .eden-pages a .eden { font-size:2.5vw; }
	body.home .eden-pages a .sub { font-size:2vw; }
	body.home .eden-pages a .btn { font-size:2vw; }
}
@media (min-width: 1280px) {
	body.home .eden-pages a .btn { font-size:1.2vw; }
}
/* /BLOCK EDEN-PAGES */
