.contenu .formulaire.loading { opacity:0.5; pointer-events:none; }
.contenu .formulaire input,
.contenu .formulaire textarea,
.contenu .formulaire select,
.contenu .formulaire button,
.contenu .formulaire .chosen-container { box-sizing:border-box; border:none; background:rgba(0,0,0,0.06); margin:10px 0; font-size:inherit; border-right:1px solid #fff; }
.contenu .formulaire input:focus,
.contenu .formulaire textarea:focus,
.contenu .formulaire select:focus,
.contenu .formulaire button:focus,
.contenu .formulaire .chosen-container:focus { background:rgba(0,0,0,0.09); }
.contenu .formulaire .chosen-single { border-radius:0; background:none; border:0; box-shadow:none; height:39px; line-height:20px; }
.contenu .formulaire .chosen-single span { color:#000; padding:10px 0; }
.contenu .formulaire .chosen-single div b { background-position:0 10px; }
.contenu .brochures label { background:no-repeat center center / contain; position:relative; cursor:pointer; margin-bottom:50px; }
.contenu .brochures label::after { content:attr(title); position:absolute; top:100%; left:50%; width:90%; white-space:nowrap; text-overflow:ellipsis; transform:translateX(-50%); overflow:hidden; text-align:center; padding-top:5px; }
.contenu .brochures label input { position:absolute; bottom:-20px; width:0px; height:0px; margin:0; }
