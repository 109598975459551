body.recherche h2 { text-align:right; }
body.recherche h2 > span { padding-right:2rem; border-right:1px solid #1e2229; }
body.recherche h2 span span { font-size: 1rem; line-height: 1rem; }

body.recherche .tris {
	background:#1e2229;
}

body.recherche .resultats {
	position:relative;
	display:block;
	line-height:0;
	padding:2px;
	box-sizing: border-box;
	background:#fff;
	margin:auto;
	font-family:'Orkney';
}
.resultats .voyage-mini {
	background:#eee no-repeat center center / cover;
	border: 2px solid #fff;
}
.resultats .voyage-mini::before {
	content:'';
	position:absolute;
	display:block;
	width:100%;
	height:100%;
	top:0;
	left:0;
	background-color: #000;
	z-index:1;
	transition:opacity 0.08s ease-in;
	opacity:0;
}
.resultats .voyage-mini::after {
	content:"Voir plus d'informations";
	position:absolute;
	display:block;
	width:100%;
	bottom:0;
	left:0;
	background-color: #1e2229;
	z-index:2;
	transition:opacity 0.08s ease-in;
	opacity:0;
	text-align:center;
	line-height:200%;
	font-size:1.5vw;
}
.resultats .voyage-mini:hover::before {
	opacity:0.5;
}
.resultats .voyage-mini:hover::after {
	opacity:0.9;
}


@media screen and (min-width: 48em) { /* md */
	/* .resultats .voyage-mini {
		display:inline-block;
		width:50%;
		padding-bottom:28%;
		vertical-align:top;
	}

	.resultats .voyage-mini:nth-child(4n) .jours,
	.resultats .voyage-mini:nth-child(4n+1) .jours {
		padding-top:3vw;
		background:url(img/sun.svg) no-repeat 88% 10% / auto 35%;
	}
	.resultats .voyage-mini:nth-child(4n) .nuits,
	.resultats .voyage-mini:nth-child(4n+1) .nuits {
		padding-top:3vw;
		background:url(img/moon.svg) no-repeat 20% 15% / auto 25%;
	} */
}
