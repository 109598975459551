header {
	background-size: cover;
	background-position: center;
	box-sizing: border-box;
	position: relative;
	z-index:2;
	/* overflow: hidden; */
	transition:height 0.6s 0.4s;
	padding-top: 75px;
	min-height: 400px;
}
.home header{
	height: 100vh;
}
.recherche header, .page header{
	height: 85vh;
}
.voyage header{
	height: 70vh;
}


/* TOP MENU */
.top-menu { display: none; }
.top-menu li { position:relative; }
.top-menu li > a { position:relative; z-index:2; cursor:pointer; }
.top-menu li ul { position:absolute; z-index:1; top:8px; left:50%; transform-origin:top center; transform:translateX(-50%) scale(0); opacity:0; transition:transform 0.2s ease-out, opacity 0.1s 0.1s ease-out; display:block; padding:25px 0 8px; border-radius:3px; background:rgba(30,34,41,0.5); }
.top-menu li ul li { display:block; margin:0; }
.top-menu li ul li a { display:block; padding:6px 12px; white-space:nowrap; text-align:center; text-transform:initial; }
.top-menu li:hover ul { transform:translateX(-50%) scale(1); opacity:1; }
.top-menu li:hover > a { color:#ffe500; }
.top-menu li ul li:hover a { background:#ffe500; color:#000; }


/* TRIGGER MENU */
header .trigger-menu {
	font-family: 'Orkney';
	position: absolute;
	top: 25px;
	right: 15px;
	z-index: 3;
	cursor: pointer;
	white-space: nowrap;
	/* transition: opacity 0.4s 1.2s, transform 0.4s 1.2s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); */
}
.menu-on header .trigger-menu {
	position: fixed;
}
header .trigger-menu > span {
	display: inline-block;
	color: #fff;
	opacity: 1;
	transition: opacity 0.2s;
}
.menu-on header .trigger-menu > span {
	opacity: 0;
}
header .trigger-menu .burger {
	display: inline-block;
	position: relative;
	height: 16px;
	width: 20px;
	margin-left: 5px;
}
header .trigger-menu .burger > span {
	position: absolute;
	left: 0;
	right: 0;
	height: 2px;
	background: #fff;
}
header .trigger-menu .burger > span:nth-child(1) {
	top: 0;
	transition: transform 0.2s, top 0.2s, right 0.2s, left 0.2s,
		background-color 0.2s;
}
.menu-on header .trigger-menu .burger > span:nth-child(1) {
	top: 50%;
	right: -4px;
	left: -4px;
	transform: translateY(-50%) rotate(45deg);
	background-color: #fff;
}
header .trigger-menu .burger > span:nth-child(2) {
	top: 50%;
	transform: translateY(-50%);
	transition: opacity 0.2s;
}
.menu-on header .trigger-menu .burger > span:nth-child(2) {
	opacity: 0;
}
header .trigger-menu .burger > span:nth-child(3) {
	bottom: 0;
	transition: transform 0.2s, bottom 0.2s, right 0.2s, left 0.2s,
		background-color 0.2s;
}
.menu-on header .trigger-menu .burger > span:nth-child(3) {
	right: -4px;
	bottom: 50%;
	left: -4px;
	transform: translateY(50%) rotate(-45deg);
	background-color: #fff;
}

/* NAV */
header > nav {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	transform: translateY(-100%);
	pointer-events: none;
	-webkit-overflow-scrolling: touch;
	transition: transform 0s 0.4s;
	overflow: hidden;
}
.menu-on header > nav {
	transform: translateY(0%);
	pointer-events: auto;
	transition: transform 0s 0s;
}
header > nav .bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0.77, 0, 0.175, 0);
	transition: background-color 0.4s;
}
.menu-on header > nav .bg {
	background: rgba(30, 34, 41, 0.5);
}
header > nav .menu {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 50px;
	background-image: linear-gradient(135deg, #00b1a0 0%, #87d1d3 100%);
	transform: translateX(100%);
	transition: transform 0.4s;
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	overflow-y: auto;
}
.menu-on header > nav .menu {
	transform: translateX(0%);
}
header > nav .menu ul {
	margin: 0;
	padding: 35px 15px 15px 45px;
	box-sizing: border-box;
	list-style-type: none;
}
header > nav .menu ul li {
	margin: 25px 0;
}
header > nav .menu ul li:first-child {
	margin-top: 0;
}
header > nav .menu ul li:last-child {
	margin-bottom: 0;
}
header > nav .menu ul a {
	display: inline-block;
	font-family: 'Orkney';
	font-weight: 600;
	font-size: 1.9em;
	color: #fff;
}
header > nav .menu ul a.active {
	color: #56c2b0;
}

/* MENU CONTACT DROITE */
#menu-contact {
	position:fixed;
	top:0;
	right:0;
	width:250px;
	bottom:0;
	background:#343530;
	z-index:10000;
	transform:translateX(100%);
	transition:transform 0.3s ease;
}
#menu-contact.open { transform:translateX(0); }
#menu-contact .actions a {
	display:block;
	color:#fff;
	padding:10px 0 10px 30px;
	background:no-repeat center left / 12px;
}
#menu-contact a[data-trigger="close-menu-contact"] {
	width:30px;
	height:30px;
	position:absolute;
	top:20px;
	right:20px;
	border:1px solid #fff;
	background:url(../img/icone-croix.svg) no-repeat center center / 12px;
	opacity:0.5;
	transition:opacity 0.3s;
}
#menu-contact a[data-trigger="close-menu-contact"]:hover {
	opacity:1;
}

header > a[data-trigger="open-menu-contact"] {
	position:absolute;
	top:100px;
	right:7px;
	color:#fff;
	z-index:2;
	background:url(../img/icone-info+.svg) no-repeat center center / contain;
	width:45px;
	height:45px;
}


/* LOGO */
header .logo {
	display: inline-block;
	transition: opacity 0.8s, transform 0.8s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.not-loaded header .logo {
	opacity: 0;
	transform: translateY(-50px);
	pointer-events: none;
}
header .logo img {
	display: block;
	width: 200px;
}

/* HEADER CONTENU */
header .contenu{
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	padding: 150px 0 0;
	display: flex;
  flex-direction: column;
  justify-content: center;
	transition: padding 0.4s 0.4s;
}
.home header .contenu{
	padding: 150px 0 60px;
	transition: padding 0.4s 0.4s;
}
header .contenu-dynamique{
	opacity: 1;
	transform: scale(1);
	transition: opacity 0.4s, transform 0.4s;
}
.transition header .contenu-dynamique{
	opacity: 0;
	transform: scale(0.9);
}

/* BG HEADER */
header .bg-header {
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;	right: 0; bottom: 0; left: 0;
	opacity: 1;
	transition: opacity 0.6s;
}
.transition .bg-header {
	opacity: 0;
	transition: opacity 0s;
}
header .bg-header::after {
	content:'';
	position:absolute; top:0; left:0; width:100%; height:300px;
	background: linear-gradient(to bottom, rgba(0,0,0,0.40) 0%,rgba(0,0,0,0) 100%);
}


/* PUNCHLINE */
.punchline {
	font-family: 'summeron';
	font-size: 1.4em;
	line-height: 1.6em;
	text-align: center;
	color: #ffe500;
	text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
	position: relative;
	margin-bottom: 25px;
	padding: 0 5px;
}
.punchline div {
	overflow: hidden;
  display: inline-block;
	margin: 0 5px;
}
.punchline span {
	display: block;
	transform: translateY(100%);
	animation-name: punchline;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
}
@keyframes punchline {
	from { transform: translateY(100%); }
	to { transform: translateY(0%); }
}

/* TITLE */
/* .voyage .punchline {
	font-size: 2.5em;
	font-family: 'Orkney';
	color: #fff;
} */

header .entete {
	position:relative;
	z-index: 1;
	/*top:0px; left:0; width:100%;*/
	text-align:center;
	transition:top 0.3s ease-in-out;
}
header .entete ul { display:none; }


/* VOYAGE MINI */
header .voyage-mini {
	display:block;
	width:100%;
	padding-bottom:35% !important;
}

/* RECHERCHE */
header .recherche {
	height: auto;
	transition: opacity 0.4s 0.2s, transform 0.4s 0.2s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.transition header .recherche {
	height: auto;
	transform: scale(0.9);
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.4s, transform 0.4s;
}
.voyage header .recherche{
	height: 0;
	opacity: 0;
	pointer-events: none;
}

header .recherche .slick-type h3 {
	font-family: 'Orkney';
	color: #fff;
	padding-left: 25px;
	padding-right: 5px;
	margin-top: 0;
	margin-bottom: 10px;
	box-sizing: border-box;
	opacity: 0.8;
	transition: opacity 0.2s, color 0.2s;
	font-size: 1.8em;
	line-height: 120%;
	text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
	text-transform: initial;
}
header .recherche .slick-type h3.slick-current {
	opacity: 1;
	color: #ffe500;
}
header .recherche .form {
	padding: 0 25px;
	box-sizing: border-box;
}
header .recherche .form > div {
	padding-right: 5px;
	margin-bottom: 10px;
	box-sizing: border-box;
}
header .recherche .form > div:nth-child(2n) {
	padding-right: 0;
	padding-left: 5px;
}
header .recherche .form .date {
	width: 100%;
	background: rgba(30, 34, 41, 0.5);
	font-size: 0.9em;
	border: 1px solid #ffe500;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	padding: 9px;
	color: #fff;
	box-sizing:border-box;
}
header .recherche .form select {
	width: 100%;
	font-size: 0.9em;
	border: 1px solid #ffe500;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	background: rgba(30, 34, 41, 0.5);
	padding: 10px;
	color: #fff;
}
header .recherche .form .chosen-container-single {
	width: 100% !important;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	font-size: 0.9em;
	border: 1px solid #ffe500;
	border-radius: 0;
}
header .recherche .form .chosen-single {
	background: rgba(30, 34, 41, 0.5);
	padding: 10px;
	box-sizing: border-box;
	color: #fff;
	line-height: 120%;
	box-shadow: none;
	border: none;
	height: auto;
}
header .recherche .form .chosen-container-single .chosen-single abbr {
	background: none;
	top: 50%;
	transform: translateY(-60%);
	width: 16px;
	height: 16px;
	right: 13px;
}
header .recherche .form .chosen-container-single .chosen-single abbr:before,
header .recherche .form .chosen-container-single .chosen-single abbr:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	transform: rotate(45deg);
	height: 2px;
	background: #fff;
	width: 16px;
}
header .recherche .form .chosen-container-single .chosen-single abbr:after {
	transform: rotate(-45deg);
}
header .chosen-container-single .chosen-single div b {
	display: none;
}
header .recherche .form .chosen-drop {
	text-transform: initial;
	letter-spacing: 0;
}

header .recherche .form ::placeholder{
	color: #fff;
}

header .recherche .form .button {
	display: inline-block;
	font-family: 'Orkney';
	font-size: 1.25em;
	font-weight: 600;
	background: #ffe500;
	padding: 10px;
	box-sizing: border-box;
	border:0;
	color:#000;
}

/* SCROLL BOT */
header .scroll-bot {
	display: inline-block;
	position: absolute;
	bottom: 25px;	left: 50%;
	z-index: 1;
	margin: 0 auto;
	height: 32px;
	width: 20px;
	border: 2px solid #fff;
	border-radius: 10px;
	cursor: pointer;
	opacity: 0;
	transform: translateX(-50%) translateY(25px);
	transition: opacity 0.4s 0.2s, transform 0.4s 0.2s;
	/* transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); */
}
.home header .scroll-bot {
	opacity: 1;
	transform: translate(-50%);
}
.transition header .scroll-bot {
	opacity: 0;
	transform: translateX(-50%) translateY(25px);
	pointer-events: none;
	transition: opacity 0.4s, transform 0.4s;
}
header .scroll-bot:before {
	content: '';
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: #fff;
	position: absolute;
	top: 15px;
	left: 50%;
	transform: translateX(-50%);
	animation-name: scroll;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}
@keyframes scroll {
	from {
		transform: translateX(-50%) translateY(0);
	}
	to {
		transform: translateX(-50%) translateY(-10px);
		opacity: 0;
	}
}

/* BARBA WRAPPER */
#barba-wrapper{
	position: relative;
}
#barba-wrapper:after{
	content:'';
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background: #fff;
	z-index: 1;
	opacity: 0;
	pointer-events: none;
}
.transition #barba-wrapper:after{
	opacity: 1;
	pointer-events: auto;
}

@media (min-width: 360px) {
	header{
		min-height: 450px;
	}
	.recherche header, .page header{
		height: 80vh;
	}
	.voyage header{
		height: 70vh;
	}
	.punchline{
		margin-bottom: 30px;
	}
}
@media (min-width: 768px) {
	header {
		min-height: 500px;
	}
	header .entete {
		position:absolute;
		top:20px; left:0; width:100%;
		text-align:center;
	}
	header .contenu {
		padding: 100px 0 0;
	}
	.home header .contenu {
		padding: 100px 0 80px;
	}
	header .scroll-bot {
		display: block;
		position: absolute;
		bottom: 40px;
	}
	.punchline div {
		overflow: hidden;
		display: block;
	}
	.punchline div:nth-child(2) span { animation-delay: 0.1s; }
	.punchline div:nth-child(3) span { animation-delay: 0.2s; }
	.punchline div:nth-child(4) span { animation-delay: 0.3s; }
	.punchline div:nth-child(5) span { animation-delay: 0.4s; }
	.punchline div:nth-child(6) span { animation-delay: 0.5s; }
}
@media (min-width: 1024px) {
	.punchline {
		margin-bottom: 50px;
	}
	header .entete { top: 20px; }
	header .logo img { width: 225px; }
	.home header .contenu {
    padding: 110px 0 80px;
	}
	header .contenu {
    padding: 100px 0 0;
	}
	header .voyage-mini > span {
		transform:translate3d(-50%,-50%,0) scale(0.45);
	}
}
@media (min-width: 1280px) {
	header {
		min-height: 730px;
	}
	header .entete {
		top: 30px;
		text-align: left;
	}
	header .logo img { margin-left:30px; width:225px; }
	header .entete:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		height: 1px;
		background: rgba(255, 255, 255, 0.35);
		transition: left 1s 0.6s;
		transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	header .entete ul { display:block; }
	header .contenu {
		padding: 100px 0 0;
	}
	.home header .contenu {
		padding: 120px 0 80px;
	}
	.punchline {
		margin-bottom: 75px;
	}
}
@media (min-width: 1680px) {
	.punchline {
    margin-bottom: 100px;
	}
}
