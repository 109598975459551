body {
	font-family: 'Quicksand', sans-serif;
	font-size: 90%;
	line-height: 120%;
	overflow-x: hidden;
}
body.not-loaded {
	overflow: hidden;
}
h1, h2, h3, h4, h5, h6 { font-family: 'Orkney'; line-height: 120%; }
h1 { font-size: 3rem; margin: 1rem 0; }
h2 { font-size: 2em; }
h3 { font-size: 1.6em; letter-spacing: 0.01em; }
h2.big { margin: 0 0 25px; padding: 0 10px; }
.sous-titre {
	text-transform: uppercase;
	color: #a7a9ac;
	letter-spacing: 0.15em;
	font-size: 0.9em;
	font-weight: 500;
	display: block;
	margin-bottom: 25px;
	padding: 0 25px;
}
a {
	text-decoration: none;
	color: #000;
}
a[data-trigger] { cursor:pointer; }
.izi-link {
	display: inline-block;
	font-family: 'Orkney';
	background: #eff0f0;
	letter-spacing: 0.05em;
	padding: 8px 16px;
	border-radius: 10px;
	margin-top: 25px;
}
.izi-link.bordered {
	background: none;
	border: 1px solid #f8c2d9;
	color: #f8c2d9;
}

.grad {
	display: inline-block;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}
.grad.blue {
	background-image: linear-gradient(135deg, #00b1a0 40%, #ffe500 60%);
	color: #65c8cf;
}
.grad.gold {
	background-image: linear-gradient(135deg, #ffd506 40%, #bb8a0b 60%);
	color: #65c8cf;
}
.grad.blueblack {
	background-image: linear-gradient(45deg, #85babe 40%, #000000 60%);
	color: #65c8cf;
}
.grad.bluevio {
	background-image: linear-gradient(135deg, #2f1f5d 40%,#529aa8 60%);
	color: #65c8cf;
}

.padded { padding-top: 50px; padding-bottom: 50px; box-sizing: border-box; }
.marged { margin-top: 50px; margin-bottom: 50px; }
.xxl-shown { display: none; }
.xxl-flex-shown { display: none; }
.slick-slide { outline:0; }

img { border:none; }
.block { display:block; }
.iblock { display:inline-block; vertical-align:top; zoom:1; *display:inline; }
.iblockb { display:inline-block; vertical-align:bottom; zoom:1; *display:inline; }
.relative { position:relative; }
.absolute { position:absolute; }
.fixed { position:fixed; }
.none, .print-only { display:none; }
.visible { visibility:visible; }
.hidden { visibility:hidden; }
[disabled] { opacity:0.5; pointer-events:none; }
.ellipsis { text-overflow:ellipsis; overflow:hidden; white-space:nowrap; }
.pointer { cursor:pointer; }
.load { background-image:url(../img/load.gif); background-repeat:no-repeat; background-position:center center; }
.required { background-image:url(../img/required.png); background-repeat:no-repeat; background-position:top right; }
.server { min-width:50px; min-height:30px; line-height:30px; padding-left:10px; }
.nowrap { white-space:nowrap; }
.uppercase { text-transform:uppercase; }
.lowercase { text-transform:lowercase; }
.erreur { color:red; }
select.erreur { color:black; border:1px solid red !important; }
.barre { text-decoration:line-through; }
.euro,.pourcent { padding-right:1em !important; position:relative; /*margin-top:6px;*/ text-align: right; white-space:nowrap; }
.euro:after, .pourcent:after { position:relative; right:-0.5em; opacity:0.5; color:#333; }
.euro:after { content: '€'; }
.pourcent:after { content: '%'; }
.euro > input { margin-right:-1.8em; padding-right:1.5em; /*margin-top:-6px;*/ text-align: right; }
.b { font-weight:bold; }
.i { font-style:italic; }
.l { text-align:left; }
.c { text-align:center; }
.r { text-align:right; }
.j { text-align:justify; }
.clear { clear:both; }
hr.clear { border:0; margin:0;  }
.w0 { width:0;}
.w30 { width:30px; }
.w50 { width:50px; }
.w60 { width:60px; }
.w70 { width:70px; }
.w80 { width:80px; }
.w90 { width:90px; }
.w100 { width:100px; }
.w125 { width:125px; }
.w150 { width:150px; }
.w175 { width:175px; }
.w200 { width:200px; }
.w250 { width:250px; }
.w300 { width:300px; }
.w400 { width:400px; }
.w500 { width:500px; }
.w600 { width:600px; }
.w700 { width:700px; }
.w800 { width:800px; }
.w900 { width:900px; }
.h50 { height:50px; }
.h100 { height:100px; }
.h150 { height:150px; }
.h200 { height:200px; }
.h250 { height:250px; }
.h300 { height:300px; }
.r5 { -webkit-border-radius:5px; -moz-border-radius:5px; -ms-border-radius:5px; border-radius:5px; }
.r10 { -webkit-border-radius:10px; -moz-border-border-radius:10px; -ms-border-border-radius:10px; border-radius:10px; }
.r15 { -webkit-border-radius:15px; -moz-border-border-radius:15px; -ms-border-border-radius:15px; border-radius:15px; }
.r20 { -webkit-border-radius:20px; -moz-border-border-radius:20px; -ms-border-border-radius:20px; border-radius:20px; }
.m0 { margin:0 !important; }
.m5 { margin:5px; }
.m10 { margin:10px; }
.m20 { margin:20px; }
.m30 { margin:30px; }
.m40 { margin:40px; }
.m50 { margin:50px; }
.m60 { margin:60px; }
.m70 { margin:70px; }
.m80 { margin:80px; }
.m90 { margin:90px; }
.m100 { margin:100px; }
.p0 { padding:0 !important; }
.p5 { padding:5px; }
.p10 { padding:10px; }
.p20 { padding:20px; }
.p30 { padding:30px; }
.p40 { padding:40px; }
.p50 { padding:50px; }
.w20p { width:19%; margin-right:1%; }
.w25p { width:24%; margin-right:1%; }
.w33p { width:32.333%; margin-right:1%; }
.w50p { width:49%; margin-right:1%; }
.w66p { width:65.666%; margin-right:1%; }
.w75p { width:74%; margin-right:1%; }
.w90p { width:89%; margin-right:1%; }
.w100p { width:99%; margin-right:1%; }

.text-shadow {
	-moz-text-shadow:2px 2px 2px rgba(0,0,0,0.3);
	-webkit-text-shadow:2px 2px 2px rgba(0,0,0,0.3);
	-o-text-shadow:2px 2px 2px rgba(0,0,0,0.3);
	-ms-text-shadow:2px 2px 2px rgba(0,0,0,0.3);
	text-shadow:2px 2px 2px rgba(0,0,0,0.3);
}
.box-shadow {
	-moz-box-shadow:0 0 16px rgba(0, 0, 0, 0.6);
	-webkit-box-shadow:0 0 16px rgba(0, 0, 0, 0.6);
	-o-box-shadow:0 0 16px rgba(0, 0, 0, 0.6);
	-ms-box-shadow:0 0 16px rgba(0, 0, 0, 0.6);
	box-shadow:0 0 16px rgba(0, 0, 0, 0.6);
}

*:focus { outline: 0; }

::-webkit-input-placeholder { opacity: 1 !important; } /* for older chrome versions. may no longer apply. */
:-moz-placeholder { opacity: 1 !important; } /* Firefox 18- */
::-moz-placeholder { opacity: 1 !important; } /* Firefox 19+ */
/* :-ms-input-placeholder { color: #FF0000; } */
