@font-face {
	font-family: 'Quicksand';
	src: url('../fonts/quicksand-regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Quicksand';
	src: url('../fonts/quicksand-medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Quicksand';
	src: url('../fonts/quicksand-bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'summeron';
	src: url('../fonts/summeron-webfont.woff2') format('woff2'),
		url('../fonts/summeron-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Orkney';
	src: url('../fonts/orkney-bold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
    font-family: 'Orkney';
    src: url('../fonts/orkney-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Orkney';
    src: url('../fonts/orkney-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
