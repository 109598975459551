
.wp-content {
	max-width:1200px;
	margin:0 auto;
	padding:25px;
}

.wp-content a {
	border-bottom:1px solid #bbb;
}

.wp-content img {
	height: auto;
	max-width: 100%;
}

.wp-caption {
	margin: 0; /* browser reset */
	max-width: 100%;
}

.wp-content dl.wp-caption dt.wp-caption-dt img {
	display: inline-block;
	margin-bottom: -1ex;
}

.wp-content dl.wp-caption,
.wp-content dl.wp-caption * {
	-webkit-user-drag: none;
}

.wp-content .aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-content .alignleft {
	float: left;
	margin: 0.5em 1em 0.5em 0;
}

.wp-content .alignright {
	float: right;
	margin: 0.5em 0 0.5em 1em;
}
