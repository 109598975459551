header::before, header::after {
	content:'';
	position:fixed;
	top: 0; right: 0; left: 0;
	z-index: 3;
	height: 5px;
	transform: translateY(-100%);
}
.transition header::before, .transition header::after {
	transform: translateY(0);
}
header::before{
	background-color: #00b1a0;
}
.transition header::after {
	background-color: #ffe500;
	animation: loading 1s ease-in-out infinite;
}

.transition .contenu{
	overflow: hidden;
}

@media (min-width: 1024px) {
	.transition header::after {
		animation: loading 1.2s ease-in-out infinite;
	}
}

@keyframes loading {
	0% {
		left: 0;
		right: 100%;
	}
	50% {
		left: 0;
		right: 0;
	}
	100% {
		left: 100%;
		right: 0;
	}
}
