/* .details-voyage {
	display: none;
	color: #fff;
}
.voyage .details-voyage {
	display: block;
} */

body.voyage .contenu h2 { padding:0 20px; margin:2.5rem 0; }
body.voyage .contenu h3 {
	display:inline-block;
	background: linear-gradient(135deg, #2f1f5d 0%,#529aa8 100%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	margin: 1.5rem 0;
}

body.voyage .bouton {
	/* background-position:5px center;background-size:15px;padding:5px 5px 5px 25px;background-color:#529aa8;border-radius:5px; */
	position:relative;
	top:0; right:0;
	width:auto; height:auto;
	border:1px solid #529aa8;
	padding:5px 10px;
	border-radius:5px;
	background-image: linear-gradient(135deg, #2f1f5d 40%,#529aa8 60%);
	background-clip:text;
	width:180px;
	margin:0 0 10px;
}
body.voyage .bouton.on {
	background-clip:initial;
	-webkit-text-fill-color:#fff;
	color:#fff;
}
body.voyage .bouton:hover {
	border:1px solid #2f1f5d;
	border-radius:5px;
	transform:none;
}

body.voyage .icones {
	text-align: center;
	background:#529aa8;
	background: linear-gradient(to right, #529aa8 0%,#2f1f5d 30%,#2f1f5d 45%,#529aa8 100%);
}
body.voyage .icones nav {
	padding-top:20px;
}
body.voyage .icones a {
	display:inline-block;
	padding:60px 5px 30px;
	margin:0 20px;
	/* border-left:1px solid #fff; */
	text-align:center;
	color:#fff;
	background:no-repeat center 20px / 24px;
}
body.voyage .icones a:first-child {
	border-left:0;
}
body.voyage .icones a.galerie-photo {
	background-image:url(../img/icone-gallerie.svg);
}
body.voyage .icones a.le-concept {
	background-image:url(../img/icone-concept.svg);
}
body.voyage .icones a.infos-pratiques {
	background-image:url(../img/icone-info-pratique.svg);
}
body.voyage .icones a.disponibilites {
	background-image:url(../img/icone-dispo.svg);
}
body.voyage .icones a.alternatives {
	background-image:url(../img/icone-gallerie.svg);
}

body.voyage .photos .slick-slide {
	background:no-repeat center center / cover;
	height:100%;
}
body.voyage .photos .slick-list,
body.voyage .photos .slick-track {
	height:100%;
}

body.voyage label.departs {
	font-family:'Orkney';
	font-size:1.0rem;
}
body.voyage label.departs .chosen-container {
	margin-top:-7px;
	font-size: 1.0rem;
}
body.voyage select.departs {
	font-family:'Orkney';
	font-size:1.0rem;
	padding:5px 5px 4px;
}

body.voyage .calendriers { text-align:center; z-index:1; }
body.voyage .calendriers-controls { position:relative; z-index:2; }
body.voyage .calendriers-controls a.previous,
body.voyage .calendriers-controls a.next {
	position:absolute;
	display:inline-block;
	top:15px;
	left:50%;
	width:100px;
	height:50px;
	background:#ddd;
	display: none !important;
}
body.voyage .calendriers-controls a.previous { transform:translateX(-250px); }
body.voyage .calendriers-controls a.next { transform:translateX(150px); }
body.voyage .calendriers-controls a.slick-disabled { opacity:0; pointer-events:none; }

.calendar { position:relative; border-collapse: separate; border-spacing: 5px; width: 100%; font-family:'Orkney'; }
.calendar caption span {
	display:inline-block;
	background: linear-gradient(135deg, #2f1f5d 0%,#529aa8 100%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size:1.5rem;
	line-height:1.5rem;
	text-align:center;
	font-family:'Orkney';
	padding: 10px 0;
}
.calendar th,
.calendar td { position:relative; height:3rem; }
.calendar th::after {
	content:attr(data-jour1);
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	font-size:0.7rem;
}
.calendar td {
	position:relative;
	border:1px solid #e4e4e4;
	color:#333;
	background:#eee;
}
.calendar td:empty {
	border:0;
	background:#fff;
}
.calendar td .day {
	position:absolute;
	top:0; right:0;
	font-size:0.7rem;
	padding:3px 5px;
	opacity:0.3;
}
.calendar td::after {
	position:absolute;
	bottom:0;
	left:0;
	padding:1px 5px;
	color:inherit;
	font-size:0.8rem;
}
.calendar td.selected {
	background: linear-gradient(135deg, #2f1f5d 0%,#529aa8 100%) !important;
	color:#fff !important;
}
/* JS DynamicSheet
.calendar td[data-depart-bod]::after { content:attr(data-depart-bod); }
.calendar td[data-depart-bod] { background:#eee; cursor:pointer; }
*/

button.reserver {
	background: linear-gradient(135deg, #2f1f5d 0%,#529aa8 100%);
	color:#fff;
	padding:10px 20px;
	border:0;
	border-radius:10px;
}

@media screen and (min-width: 35.5em) { /* sm */
	body.voyage .icones {
		background: linear-gradient(to bottom, #529aa8 0%,#2f1f5d 30%,#2f1f5d 45%,#529aa8 100%);
	}
	body.voyage .icones a {
		display:block;
		padding:60px 5px 30px;
		margin:0 20px;
		border-left:0;
		border-top:1px solid #fff;
	}
	body.voyage .icones nav {
		padding-top:0;
	}
	body.voyage .icones a:first-child {
		border-top:0;
		margin-top:50px;
	}
	body.voyage .sliders {
		margin: 0 10px;
	}
	.slider.photos {
		border-top: 5px solid #fff;
		border-right: 10px solid #fff;
		border-bottom: 5px solid #fff;
		box-sizing: border-box;
	}
	body.voyage .photos-liste {
		position: relative;
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
	}
	body.voyage .photos-liste:before, body.voyage .photos-liste:after {
		content: '';
		position: absolute;
		top: 0; right: 0; left: 0;
		height: 5px;
		background: #fff;
		z-index: 1;
	}
	body.voyage .photos-liste:after {
		top: auto; right: 0; bottom: 0; left: 0;
	}
	body.voyage .photos-liste .slick-list {
		height: 100%;
	}
	body.voyage .photos-liste .slick-slide {
		background:no-repeat center center / cover;
		padding-bottom: 75%;
		border-top: 5px solid #fff;
		border-bottom: 5px solid #fff;
		box-sizing: border-box;
		opacity: 0.7;
		transition: opacity 0.25s;
	}
	body.voyage .photos-liste .slick-slide.slick-current {
		opacity: 1;
	}
	body.voyage .photos-liste .slick-slide:focus {
		outline:0;
	}

	.calendar th::after { content:attr(data-jour3); }
}

@media screen and (min-width: 48em) { /* md */
	.calendar th,
	.calendar td { height:4rem; }
	.calendar td::after { font-size:1.2rem; }
	.calendar td .day { font-size:1rem; }
}

@media screen and (min-width: 64em) { /* lg */
	.calendar th::after { content:attr(data-jour); }
}

@media screen and (min-width: 80em) { /* xl */
}
