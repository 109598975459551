footer {
	background:#343530 url(../img/icone-mapronde.svg) no-repeat 97% center / 60px;
}
footer ul { list-style:none; padding:5vh 0; margin:0; text-align:center; }
footer li { margin:0; padding:0; display:inline-block; }
footer a {
	display:inline-block;
	color:#fff;
	opacity:0.5;
	transition:opacity 0.3s;
	padding:10px 30px;
}
footer a:hover {
	opacity:1;
}
