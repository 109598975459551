/* popup */
/* .popup { position:fixed; top:0px; right:0px; transition:transform 0s 0.3s; bottom:0px; left:0px; z-index: 10000; transform:translateX(-100%); }
.popup.on { transform:translateX(0); transition:transform 0s 0s; }
.popup .mask { background:rgba(14, 14, 15, 0.75); position:absolute; top:0px; right:0px; bottom:0px; left:0px; opacity:0; transition:opacity 0.3s; transform:translateX(-100%); }
.popup.on .mask { opacity:1; transform:translateX(0); transition:opacity 0.2s; }
.popup .container{
    position: absolute; top:50%; left:50%;
    transform:translate3d(-50%,calc(-50% + 100px),0);
	width:90%;
    margin: 0 auto;
    background:#f4f5f7;
    padding:50px;
    box-sizing:border-box;
    text-align:center;
    max-height: 90%;
	transition:transform 0.2s, opacity 0.2s;
	opacity:0;
}
.popup.on .container { opacity:1; transform:translate3d(-50%,-50%,0); }
.popup.loading .container::after { content:''; position:absolute; top:0; left:0; bottom:0; right:0; background:url(img/loader.svg) no-repeat center center / 10%; }
.popup.loading .container > div { opacity:0.5; pointer-events:none; }
.popup .container .close { display:inline-block; width:50px; height:50px; background:#ef5a57 url(img/picto-close.svg) no-repeat center center / contain; text-indent:150%; overflow:hidden; position:absolute; top:-25px; right:-25px; border-radius:100px; transition:transform 0.2s; cursor:pointer; }
.popup .container .close:hover { transform:rotate( 90deg) scale(1.3); }
.popup .container h2 { margin:0 0 25px; }
.popup a { text-decoration:none; color:#ef5a57; }
.popup .container .liens a {
    display:inline-block;
    margin:5px auto;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    background: #ef5a57;
    padding: 10px 15px;
    text-decoration: none;
} */
.popup {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3;
	transform: translateY(-100%);
	pointer-events: none;
	-webkit-overflow-scrolling: touch;
	transition: transform 0s 0.4s;
	overflow: hidden;
}
.popup.on {
	transform: translateY(0%);
	pointer-events: auto;
	transition: transform 0s 0s;
}
.popup .bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0.77, 0, 0.175, 0);
	transition: background-color 0.4s;
}
.popup.on .bg {
	background: rgba(30, 34, 41, 0.5);
}
.popup .container {
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	width: 95vw;
	max-width: 600px;
	opacity: 0;
	transition: top 0.4s, opacity 0.4s;
	overflow: auto;
}
.popup.on .container {
	opacity: 1;
	top: 50%;
}
.popup .container h3 {
	margin-top: 0;
	margin-bottom: 0;
	background-color: #1e2229;
	color: #fff;
	padding: 25px 25px 15px;
	box-sizing: border-box;
	text-transform: none;
}
.popup.loading .container::after { content:''; position:absolute; top:0; left:0; bottom:0; right:0; background:url(img/loader.svg) no-repeat center center / 10%; }
.popup.loading .container > div { opacity:0.5; pointer-events:none; }

/* /popup */


/* popup rappel */
.popup .rappel .container { max-width:700px;}
.popup .rappel input,
.popup .rappel button { box-sizing:border-box; border:none; background:rgba(0,0,0,0.06); margin:10px 0; font-size:inherit; border-right:1px solid #fff; }
.popup .rappel input:focus { background:rgba(0,0,0,0.09); }
.popup .rappel button { max-width:300px; background:#ffe500; color:#000; font-weight:bold; }
.popup .rappel .serveur { color:#000; }
/* /popup rappel */
