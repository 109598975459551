.voyage-mini {
	position:relative;
	display:block;
	width:100%;
	padding-bottom:50%;
	line-height:normal;
	box-sizing: border-box;
	overflow:hidden;
	color:#fff;
	text-shadow:0 0 10px rgba(0,0,0,1);
}
.voyage-mini > span {
	position:absolute;
	display:block;
	top:50%;
	left:50%;
	/* width:90%; */
	transform:translate3d(-50%,-50%,0) scale(0.99);
	transition:background-color 0.2s ease;
	text-align:center;
	z-index:1;
}
.voyage-mini .titre,
.voyage-mini .duree,
.voyage-mini .prix {
	display: block;
}
.voyage-mini .titre {
	display:block;
	font-size:6vw;
	font-weight:bold;
}
.voyage-mini .duree {
	display:inline-block;
	font-size:4vw;
	margin:1.5vw auto;
	white-space: nowrap;
}
.voyage-mini .duree span {
	display:inline-block;
	width:16vw;
	box-sizing:border-box;
	font-size:4vw;
	line-height:4vw;
}
.voyage-mini .duree span::after {
	display:block;
	font-size:3vw;
	font-weight:normal;
}
.voyage-mini .jours {
	padding-left:5vw;
	background:url(img/sun.svg) no-repeat 0 50% / auto 70%;
	border-right:1px solid #fff;
}
.voyage-mini .nuits {
	padding-right:5vw;
	background:url(img/moon.svg) no-repeat 100% 50% / auto 50%;
}
.voyage-mini .jours::after { content:'jours'; }
.voyage-mini .nuits::after { content:'nuits'; }
.voyage-mini .prix-mini {
	display:block;
	margin:0 auto;
	font-size:6vw;
	font-weight:bold;
}
.voyage-mini .prix-mini::before {
	content:'A partir de ';
	display:block;
	font-size:3vw;
	letter-spacing:0.4vw;
	text-transform:uppercase;
	font-weight:normal;
}

@media (min-width: 35.5em) { /* sm */
	.voyage-mini > span { transform:translate3d(-50%,-50%,0) scale(0.65); }
}
@media (min-width: 48em) { /* md */
	.voyage-mini {
		display:inline-block;
		width:50%;
		padding-bottom:28%;
		vertical-align:top;
	}
	.voyage-mini > span { transform:translate3d(-50%,-50%,0) scale(0.45); }
	.voyage-mini:nth-child(4n),
	.voyage-mini:nth-child(4n+1) { padding-bottom:41%; }
	.voyage-mini:nth-child(4n+4) { margin-top:-13%; }

	.voyage-mini:nth-child(4n) .jours,
	.voyage-mini:nth-child(4n+1) .jours {
		padding:8vw 0 0 0;
		background:url(img/sun.svg) no-repeat 50% 10% / auto 35%;
		width:11vw;

	}
	.voyage-mini:nth-child(4n) .nuits,
	.voyage-mini:nth-child(4n+1) .nuits {
		padding:8vw 0 0 0;
		background:url(img/moon.svg) no-repeat 50% 15% / auto 25%;
		width:11vw;
	}
}
@media screen and (min-width: 64em) { /* lg */
	.voyage-mini > span { transform:translate3d(-50%,-50%,0) scale(0.35); }
}
@media screen and (min-width: 80em) { /* xl */
	.voyage-mini > span { transform:translate3d(-50%,-50%,0) scale(0.30); }
}
