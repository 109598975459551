.agences h2 { text-align:center; }
.slick-agences-dots ul { display:block; margin:0; padding:0; text-align:center; }
.slick-agences-dots li { display:inline-block; margin:0; padding:0; margin:0 7px; }
.slick-agences-dots button { color:#fff; background:#b8bfc5; border-radius:100px; width:40px; height:40px; border:0; font-family:Orkney; font-size:21px; text-align:center; padding:0; line-height:43px; }
.slick-agences-dots .slick-active button { background:#274054; }
.slick-agences .slick-slide { position:relative; font-family:Orkney; }
.slick-agences .slick-slide > div { margin-top:20px; padding-top:20px; }
.slick-agences .slick-slide h3 { position:absolute; top:20px; left:50%; transform:translate(-50%, -23px); display:inline-block; background:#fff; padding:0 30px; color:#767676; text-transform:uppercase; font-size:15px; }
.slick-agences .slick-slide h4 { color:#767676; text-transform:uppercase; font-size:22px; padding:4px 0 0 55px; background:url(img/picto-agence.svg) no-repeat left top / 50px; }
.slick-agences .slick-slide .telephone { color:#767676; font-size:18px; font-weight:bold; }
.slick-agences .slick-slide .adresse { color:#767676; font-size:16px; margin-top:10px; }
.slick-agences .slick-slide .horaires { margin:10px 0 0; padding:0; list-style:none; font-size:14px; }
.slick-agences .slick-slide .horaires ul { margin:0; padding:0; list-style:none; }
.slick-agences .slick-slide .horaires li { margin:10px 0 0; padding:0;  }
.slick-agences .slick-slide a { display:inline-block; text-align:center; color:#274054; text-transform:uppercase; font-size:11px; margin-bottom:10px; border:0; }
.slick-agences .slick-slide a img { background:#274054; border:2px solid #274054; border-radius:1000px; width:100%; }
.slick-agences .slick-slide .img { margin-top:10px; }
