.popup .favoris .favori {
	padding: 25px;
	box-sizing: border-box;
	border-bottom: 1px solid #eff0f0;
}
.popup .favoris .favori:last-child {
	border-bottom: none;
}
.popup .favoris .visuel {
	background-size: cover;
	background-position: center;
}
.popup .favoris .detail {
	padding: 10px 0 10px 25px;
	box-sizing: border-box;
}
.popup .favoris h4 {
	margin-top: 0;
	margin-bottom: 5px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}
.popup .favoris span {
	color: #939598;
}
.popup .popup-close {
	position: absolute;
	top: 25px;
	right: 25px;
	width: 25px;
	height: 25px;
}
.popup .popup-close:before,
.popup .popup-close:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	width: 25px;
	height: 2px;
	background: #fff;
	transform: rotate(45deg);
}
.popup .popup-close:after {
	transform: rotate(-45deg);
}

.favoris .liste { max-height:70vh; overflow:auto; }

.favoris > .clear {
	position:absolute;
	width:25px;
	height:25px;
	top:25px;
	right:80px;
	background:url(../img/corbeille.svg) no-repeat center center / contain;
}

.favori {
	position:relative;
}

.favori > .clear {
	position:absolute;
	width:25px;
	height:25px;
	top:6px;
	right:6px;
	background:#fff;
	opacity:0;
	transition:opacity 0.2s ease;
}
.favori > .clear:before,
.favori > .clear:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	width: 25px;
	height: 2px;
	background: #888;
	transform: rotate(45deg);
}
.favori > .clear:after {
	transform: rotate(-45deg);
}
.favori:hover > .clear {
	opacity:0.5;
}
.favori:hover > .clear:hover {
	opacity:1;
}



.favori-star {
	position:absolute;
	top:8px;
	right:8px;
	width:15px;
	height:15px;
	border:0;
	background:url(../img/star.svg) no-repeat center center / cover;
	z-index:2;
	transition:all 0.2s ease-in-out;
}
.favori-star.on {
	background-image:url(img/star-on.svg);
}
.favori-star:hover {
	transform:scale(1.2);
}
